import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import TeamMember from './team_member.vue';

formCreate.component('TeamMember', TeamMember);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'queus_manage_form',
    };
  },

  methods: {
    setRule(item) {
      // const v = item;
      // if (v.field === 'productName') { // 选择产品
      //   v.props = {
      //     ...v.props,
      //     params: {
      //       functionCode: 'standard_product_list',
      //     },
      //     getValue: (field) => this.getFieldValue(field),
      //     title: v.title,
      //   };
      // }
      return item;
    },
    // async getUserInfoObjDetails(id = '') {
    //   request.get('/mdm/mdmUserController/detail', { id }).then((res) => {
    //     if (res.success) {
    //       const { result } = res;

    //       delete result.positionList;

    //       console.log(73333, result);

    //       this.infoObj = {
    //         ...result,
    //         userPassword: this.passwordDefault,
    //       };

    //       setTimeout(() => {
    //         this.setValue(this.infoObj);
    //       }, 100);
    //     }
    //   });
    // },

    formComplete() {
      // const { type, id } = this.formConfig;
      // const productName = this.getRule('productName');
      // productName.on.change = (e) => {
      //   if (e) {
      //     this.setValue({
      //       productName: e[0].materialName,
      //       productCode: e[0].materialCode,
      //       productSeries: e[0].productLevelName,
      //       calculateUnit: e[0].baseUnit,
      //       calculateUnitName: e[0].baseUnitName,

      //     });
      //   } else {
      //     this.setValue({
      //       productName: null,
      //       productCode: null,
      //       productSeries: null,
      //       calculateUnit: null,
      //       calculateUnitName: null,
      //     });
      //   }
      // };
      // const warehousingStandard = this.getRule('warehousingStandard');
      // warehousingStandard.props = {
      //   ...warehousingStandard.props,
      //   placeholder: '入库标准(%)',
      // };

      // if (type === 'edit' || type === 'view') {
      //   request.get('/tpm/tpmWarehousingCalculateStandard/query', { id }).then((res) => {
      //     if (res.success) {
      //       this.setValue(res.result);
      //     }
      //   });
      // }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        // 默认新增
        let url = '/tpm/tpmWarehousingCalculateStandard/add';
        // 有id  就是编辑
        if (this.formConfig.id) {
          url = '/tpm/tpmWarehousingCalculateStandard/update';
        }
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
